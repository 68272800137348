import ENV from 'additive-newsletter/config/environment';

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

import { MEDIA_ENGINE_NAME } from '@additive-apps/media/constants';

export default class InstanceMultimediaIndexController extends Controller {
  @service authenticatedFetch;
  @service currentUser;
  @service intl;
  @service multimedia;
  @service router;
  @service uiDialog;
  @service uiToast;

  /**
   * The currentFolder model
   * @property currentFolder
   * @type {Medium}
   */
  currentFolder = null;

  get _multimediaBaseRouteName() {
    const currentRoute = this.router.currentRouteName;
    const index = currentRoute.indexOf(MEDIA_ENGINE_NAME);

    // extract route path including engine name
    return currentRoute.substr(0, index + MEDIA_ENGINE_NAME.length);
  }

  @action
  openFilter() {
    this.multimedia.folderBeforeFilter = this.multimedia.currentFolder;

    this.router.transitionTo(`${this._multimediaBaseRouteName}.filter`);
  }

  /**
   * Downloads the content of the folder
   *
   * @property downloadFolder
   * @param {Object} folder, the folder to be downloaded
   * @type {Task}
   */
  @task(function* (folder) {
    if (!folder) {
      return this.uiDialog.showError();
    }
    try {
      const response = yield this.authenticatedFetch.fetch(
        `${ENV.APP.mediaApi}/${this.currentUser.currentOrganization.id}/folders/${folder.id}/download`,
        {
          headers: {
            Accept: 'application/vnd.additive+json; version=1'
          },
          method: 'POST'
        },
        null,
        [404]
      );

      if (!response || !response.ok) {
        throw response;
      }

      this.uiToast.showToast({
        title: this.intl.t('multimedia.bulkDownload.success.title'),
        description: this.intl.t('multimedia.bulkDownload.success.description'),
        type: 'success'
      });
    } catch (e) {
      if (e.status === 404) {
        this.uiDialog.showError(
          this.intl.t('multimedia.bulkDownload.error.title'),
          this.intl.t('multimedia.bulkDownload.error.description')
        );
        return;
      }
      this.uiDialog.showError();
    }
  })
  downloadFolder;

  @action
  onDownloadFolder(folder) {
    const intl = this.intl;
    this.uiDialog.showConfirm(
      intl.t('multimedia.bulkDownload.confirm.title'),
      intl.t('multimedia.bulkDownload.confirm.description'),
      () => this.downloadFolder.perform(folder),
      intl.t('multimedia.bulkDownload.download'),
      true
    );
  }
}
