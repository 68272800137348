import ENV from 'additive-newsletter/config/environment';

import Controller from '@ember/controller';
import { isArray } from '@ember/array';
import { computed, set, setProperties } from '@ember/object';
import { alias, not } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/string';
import { task, timeout, all } from 'ember-concurrency';
import format from 'date-fns/format';

import { parseDate } from '@additive-apps/ui/utils/date-util';

import { ACCEPT_HEADER } from 'additive-newsletter/utils/constants';

const SETUP_FILE_PATH = 'guides/ASA-Einrichtung.zip';

export default Controller.extend({
  authenticatedFetch: service(),
  currentUser: service(),
  intl: service(),
  router: service(),
  uiToast: service(),
  uiDialog: service(),
  uiAppSettings: service(),

  /* Google Analytics related query params */
  queryParams: ['status', 'code', 'profiles', 'redirectRoute', 'key'],

  /**
   * the fetched integration settings
   *
   * @argument integrations
   * @type {Object}
   * @default null
   */
  integrations: null,

  /**
   * the fetched subscriber integration settings
   *
   * @argument subscriberIntegrationSettings
   * @type {Object}
   * @default null
   */
  subscriberIntegrationSettings: null,

  /**
   * whether an error occurred during fetching of data
   *
   * @argument isError
   * @type {Boolean}
   * @default false
   */
  isError: false,

  /**
   * whether the google analytics dialog is shown
   *
   * @argument isGoogleAnalyticsDialog
   * @type {Boolean}
   * @default false
   */
  isGoogleAnalyticsDialog: false,

  /**
   * whether the asa integration dialog is shown
   *
   * @argument isPmsIntegrationDialog
   * @type {Boolean}
   * @default false
   */
  isPmsIntegrationDialog: false,

  /**
   * whether the additive registration integration dialog is open
   *
   * @argument isSubscriberIntegrationDialog
   * @type {Boolean}
   * @default false
   */
  isSubscriberIntegrationDialog: false,

  /**
   * whether the enquiry integration dialog is shown
   *
   * @argument isEnquiryIntegrationDialog
   * @type {Boolean}
   * @default false
   */
  isEnquiryIntegrationDialog: false,

  leadsType: null,

  isLeadsDialogOpen: false,

  /**
   * code query param for Google Analytics
   *
   * @argument code
   * @type {String}
   * @default null
   */
  code: null,

  /**
   * profiles query param for Google Analytics
   *
   * @argument profiles
   * @type {String}
   * @default null
   */
  profiles: null,

  /**
   * redirectRoute query param for Google Analytics
   *
   * @argument redirectRoute
   * @type {String}
   * @default null
   */
  redirectRoute: null,

  /**
   * status query param for Google Analytics
   *
   * @argument status
   * @type {String}
   * @default null
   */
  status: null,

  /**
   * Specific details about the currently used pms
   */
  _pmsIntegrations: null,

  /**
   * whether settings are being fetched
   *
   * @computed isLoading
   * @type {Boolean}
   */
  isLoading: alias('fetchIntegrationSettings.isRunning'),

  /**
   * whether the user can open the professional-only integration settings
   *
   * @computed isIntegrationActionsDisabled
   * @type {Boolean}
   */
  isIntegrationActionsDisabled: not('isProfessionalPlan'),

  /**
   * whether the app has the professional plan
   *
   * @computed isProfessionalPlan
   * @type {Boolean}
   */
  isProfessionalPlan: alias('uiAppSettings.isProfessionalPlan'),

  /**
   * whether the user has read only rights
   *
   * @computed isReadOnly
   * @type {Boolean}
   */
  isReadOnly: not('currentUser.isManager'),

  /**
   * whether the current user is of role "viewer"
   *
   * @computed isViewer
   * @type {Boolean}
   */
  isViewer: alias('currentUser.isViewer'),

  organizationSlug: alias('currentUser.currentOrganization.id'),

  /**
   * whether a PMS is defined
   *
   * @computed _hasPms
   * @type {Boolean}
   */
  _hasPms: computed('integrations.pms', {
    get() {
      return ['asa', 'fidelio', 'mews'].includes(this.integrations?.pms?.type);
    }
  }),

  /**
   * description for the on-page lead ad integration setting
   *
   * @computed onPageLeadDescription
   * @type {String}
   */
  onPageLeadDescription: computed('integrations.pmsSync.enabled', 'isProfessionalPlan', {
    get() {
      if (!this.isProfessionalPlan) {
        return htmlSafe(this.intl.t('settings.integrations.on-page-leads.upgradeForFeature'));
      }

      return htmlSafe(
        this.intl.t('settings.integrations.on-page-leads.description', {
          active: this.integrations.onPageLeads?.enabled
        })
      );
    }
  }),

  /**
   * description for the referral lead ad integration setting
   *
   * @computed referralLeadDescription
   * @type {String}
   */
  referralLeadDescription: computed('integrations.pmsSync.enabled', 'isProfessionalPlan', {
    get() {
      if (!this.isProfessionalPlan) {
        return htmlSafe(
          this.intl.t('settings.integrations.referral-marketing-leads.upgradeForFeature')
        );
      }

      return htmlSafe(
        this.intl.t('settings.integrations.referral-marketing-leads.description', {
          active: this.integrations.referralMarketingLeads?.enabled
        })
      );
    }
  }),

  /**
   * description for the lead ad integration setting
   *
   * @computed leadAdDescription
   * @type {String}
   */
  facebookLeadAdDescription: computed('integrations.pmsSync.enabled', 'isProfessionalPlan', {
    get() {
      if (!this.isProfessionalPlan) {
        return htmlSafe(this.intl.t('settings.integrations.facebook-leads.upgradeForFeature'));
      }

      return htmlSafe(
        this.intl.t('settings.integrations.facebook-leads.description', {
          active: this.integrations.facebookLeads?.enabled
        })
      );
    }
  }),

  /**
   * description for the TikTok lead ad integration setting
   *
   * @computed tiktokLeadDescription
   * @type {String}
   */
  tiktokLeadDescription: computed('integrations.tiktokLeads', 'isProfessionalPlan', {
    get() {
      if (!this.isProfessionalPlan) {
        return htmlSafe(this.intl.t('settings.integrations.tiktok-leads.upgradeForFeature'));
      }

      return htmlSafe(
        this.intl.t('settings.integrations.tiktok-leads.description', {
          active: this.integrations.tiktokLeads?.enabled
        })
      );
    }
  }),

  /**
   * computed description for the pms integration setting
   *
   * @computed pmsDescription
   * @type {String}
   */
  pmsDescription: computed('integrations.pmsSync.enabled', 'isProfessionalPlan', {
    get() {
      const { type } = this.integrations.pms;
      if (!this.isProfessionalPlan) {
        return htmlSafe(
          this.intl.t(`settings.integrations.pmsIntegration.${type}.upgradeForFeature`)
        );
      }

      if (!this.integrations || !this.integrations.pmsSync.enabled) {
        return htmlSafe(this.intl.t(`settings.integrations.pmsIntegration.${type}.syncDisabled`));
      }

      return htmlSafe(this.intl.t(`settings.integrations.pmsIntegration.${type}.syncEnabled`));
    }
  }),

  // path to the asa setup pdf
  _asaInstructionDownloadLink: computed({
    get() {
      return `${ENV.APP.publicHost}/${SETUP_FILE_PATH}`;
    }
  }),

  /**
   * computed description for the registration integration setting
   *
   * @computed subscriberIntegrationDescription
   * @type {String}
   */
  subscriberIntegrationDescription: computed(
    'isProfessionalPlan',
    'subscriberIntegrationSettings.addressBookId',
    {
      get() {
        if (!this.isProfessionalPlan) {
          return this.intl.t(
            'settings.integrations.additiveIntegrations.subscriberIntegration.upgradeForFeature'
          );
        }

        if (
          !this.subscriberIntegrationSettings ||
          !this.subscriberIntegrationSettings?.token ||
          !this.subscriberIntegrationSettings?.addressBookId
        ) {
          return this.intl.t(
            'settings.integrations.additiveIntegrations.subscriberIntegration.disabled'
          );
        }

        return this.intl.t(
          'settings.integrations.additiveIntegrations.subscriberIntegration.enabled'
        );
      }
    }
  ),
  /**
   * The status of the subscriber integration
   *
   * We differ these cases:
   *  - error: Subscriber integration is not active
   *  - warning: More than 7 days passed since last usage of token
   *  - success: Token was used within last 7 days
   *
   * @returns {String} message date of last import
   * @returns {String} status, the subscriber integration connection status for the status indicator
   */
  subscriberIntegrationState: computed(
    'subscriberIntegrationSettings.addressBookId',
    'isProfessionalPlan',
    {
      get() {
        if (
          !this.isProfessionalPlan ||
          !this.subscriberIntegrationSettings ||
          !this.subscriberIntegrationSettings?.lastUsedAt ||
          !this.subscriberIntegrationSettings?.addressBookId
        ) {
          let status = '';
          let message;

          if (!this.subscriberIntegrationSettings?.addressBookId) {
            message = this.intl.t(
              'settings.integrations.additiveIntegrations.subscriberIntegration.status.deactivated'
            );
          } else {
            status = 'error';
            message = this.intl.t(
              'settings.integrations.additiveIntegrations.subscriberIntegration.status.neverUsed'
            );
          }

          return { status, message };
        }

        const { lastUsedAt } = this.subscriberIntegrationSettings;

        const now = Date.now();
        const ONE_WEEK = 7 * 24 * 60 * 60 * 1000;

        // Check if integration is active (max. 7 days passed)
        const isActive = now - new Date(lastUsedAt).getTime() <= ONE_WEEK;

        const formattedLastUsedAt = lastUsedAt && format(parseDate(lastUsedAt), 'dd.MM.yyyy');

        const message = this.intl.t(
          'settings.integrations.additiveIntegrations.subscriberIntegration.status.lastUsedAt',
          { date: formattedLastUsedAt }
        );

        const status = isActive ? 'success' : 'warning';

        return {
          status,
          message
        };
      }
    }
  ),

  /**
   * The connection-status to PMS
   *
   * We differ these cases:
   *  - error: PMS sync is not active or more than 48h have passed since last import and export
   *  - warning: More than 48h have passed since last import or export
   *  - success: PMS sync, import and export is active
   *
   * @returns {String} importDate, date of last import
   * @returns {String} exportDate, date of last export
   * @returns {Boolean} isImportActive, whether import is active
   * @returns {Boolean} isExportActive, whether export is active
   * @returns {String} status, the PMS connection status for the status indicator
   */
  pmsSyncState: computed('integrations.pmsSync.enabled', 'isProfessionalPlan', {
    get() {
      if (!this.integrations?.pmsSync || !this.integrations?.pms) {
        return;
      }

      let { enabled, lastImport, lastExport } = this.integrations.pmsSync;
      const { type } = this.integrations.pms;
      let status;

      const now = Date.now();
      const TWO_DAYS = 2 * 24 * 60 * 60 * 1000;

      // Check if import/export is active (max. 48h passed)
      const isImportActive = now - new Date(lastImport).getTime() <= TWO_DAYS;
      const isExportActive = now - new Date(lastExport).getTime() <= TWO_DAYS;

      // Format import/export date
      const formattedLastImport = lastImport && format(parseDate(lastImport), 'dd.MM.yyyy');
      const formattedLastExport = lastExport && format(parseDate(lastExport), 'dd.MM.yyyy');

      let importMessage = this.intl.t(
        `settings.integrations.pmsIntegration.${type}.status.import.message`,
        { date: formattedLastImport }
      );
      let exportMessage = this.intl.t(
        `settings.integrations.pmsIntegration.${type}.status.export.message`,
        { date: formattedLastExport }
      );

      if (isImportActive && isExportActive) {
        status = 'success';
      } else {
        if (!formattedLastImport) {
          importMessage = this.intl.t(
            `settings.integrations.pmsIntegration.${type}.status.import.noImportMessage`
          );
        }
        if (!formattedLastExport) {
          exportMessage = this.intl.t(
            `settings.integrations.pmsIntegration.${type}.status.export.noExportMessage`
          );
        }

        if (isImportActive || isExportActive) {
          status = 'warning';
        } else {
          status = 'error';
        }
      }

      if (!this.isProfessionalPlan || !this.integrations || !enabled) {
        importMessage = this.intl.t(
          `settings.integrations.pmsIntegration.${type}.status.deactivated`
        );
        exportMessage = '';
        status = '';
      }

      return {
        importMessage,
        exportMessage,
        status
      };
    }
  }),

  /**
   * Route to the account for configuring alpineBits
   *
   * @computed accountRoute
   * @type {String}
   */
  accountIntegrationsLink: computed({
    get() {
      return `${ENV.APP.accountHost}/${this.organizationSlug}/settings/integrations`;
    }
  }),

  /**
   * computed description for the enquiry integration setting
   *
   * @computed enquiryDescription
   * @type {String}
   */
  enquiryDescription: computed('integrations.enquirySyncEnabled', 'isProfessionalPlan', {
    get() {
      if (!this.isProfessionalPlan) {
        return this.intl.t('settings.integrations.appsIntegration.upgradeForFeature');
      }
      if (!this.integrations || !this.integrations?.enquiry?.enabled) {
        return this.intl.t('settings.integrations.appsIntegration.syncDisabled');
      }

      return this.intl.t('settings.integrations.appsIntegration.syncEnabled');
    }
  }),

  /**
   * The connection-status to enquiry
   *
   * We differ these cases:
   *  - success: Enquiry sync is active
   *  - error: Enquiry sync is not active
   *
   * @returns {String} importDate, date of last import
   * @returns {String} status, the enquiry connection status for the status indicator
   */
  enquiryState: computed('integrations.enquirySyncEnabled', 'isProfessionalPlan', {
    get() {
      if (!this.integrations?.enquiry) {
        return;
      }

      let { lastImport } = this.integrations.enquiry;
      let status = 'success';
      let importMessage;

      // Format import date
      const formattedLastImport = lastImport && format(parseDate(lastImport), 'dd.MM.yyyy');

      if (formattedLastImport) {
        importMessage = this.intl.t('settings.integrations.appsIntegration.status.import.message', {
          date: formattedLastImport
        });
      } else {
        importMessage = this.intl.t(
          'settings.integrations.appsIntegration.status.import.noImportMessage'
        );
      }

      if (!this.isProfessionalPlan || !this.integrations?.enquiry?.enabled) {
        status = '';
        importMessage = this.intl.t('settings.integrations.appsIntegration.status.deactivated');
      }

      return {
        importMessage,
        status
      };
    }
  }),

  /**
   * computed description for the google analytics integration setting
   *
   * @computed googleAnalyticsDescription
   * @type {String}
   */
  googleAnalyticsDescription: computed('integrations.googleAnalyticsProfile', {
    get() {
      if (
        !this.integrations ||
        !this.integrations.googleAnalyticsProfile ||
        this.integrations.googleAnalyticsProfile.length === 0
      ) {
        return htmlSafe(
          this.intl.t('settings.integrations.googleAnalyticsIntegration.syncDisabled')
        );
      }

      const googleAnalyticsProfile = this.integrations.googleAnalyticsProfile;

      const accountName = googleAnalyticsProfile.account && googleAnalyticsProfile.account.name;
      const propertyName = googleAnalyticsProfile.property && googleAnalyticsProfile.property.name;
      const viewName = googleAnalyticsProfile.view && googleAnalyticsProfile.view.name;

      return [accountName, propertyName, viewName].filter(Boolean).join(' - ');
    }
  }),

  /**
   * computed object of google analytics params
   *
   * @computed googleAnalyticsParams
   * @type {Object}
   */
  googleAnalyticsParams: computed('status', 'code', 'profiles', 'redirectRoute', {
    get() {
      const queryParams = this.queryParams;

      let googleAnalyticsParams = {};

      if (isArray(queryParams)) {
        queryParams.forEach((param) => {
          if (this.get(param)) {
            googleAnalyticsParams[param] = this.get(param);
          }
        });
      }

      return Object.keys(googleAnalyticsParams).length > 0 ? googleAnalyticsParams : null;
    }
  }),

  /**
   * computed state for the google analytics integration setting
   *
   * @computed googleAnalyticsState
   * @type {String}
   */
  googleAnalyticsState: computed('integrations.googleAnalyticsProfile', {
    get() {
      if (
        !this.integrations ||
        !this.integrations.googleAnalyticsProfile ||
        this.integrations.googleAnalyticsProfile.length === 0
      ) {
        return '';
      }

      return 'success';
    }
  }),

  /**
   * fetches the integration settings
   *
   * @function fetchIntegrationSettings
   * @type {Task}
   */
  fetchIntegrationSettings: task(function* () {
    set(this, 'isError', false);
    const url = `${ENV.APP.apiBaseHost}/${this.organizationSlug}`;

    try {
      let tasks = [];

      const request = this.authenticatedFetch.fetch(`${url}/settings/integrations`, {
        headers: ACCEPT_HEADER
      });

      tasks.push(request);
      tasks.push(timeout(400));

      const [response] = yield all(tasks);

      if (!response || !response.ok) {
        throw new Error('[SETTINGS] integrations', response);
      }

      const json = yield response.json();
      if (!json || !json.integrations) {
        throw new Error('[SETTINGS] integrations');
      }

      set(this, 'integrations', json.integrations);
      yield this.fetchSubscriberIntegrationSettings.perform();

      if (this.key) {
        switch (this.key) {
          case 'pmsIntegrations.asa':
            set(this, 'isPmsIntegrationDialog', true);
            break;
          case 'appIntegrations.enquiry':
            set(this, 'isEnquiryIntegrationDialog', true);
            break;
          case 'leadIntegrations.facebook':
            set(this, 'isFacebookLeadIntegrationDialog', true);
            break;
          case 'leadIntegrations.on-page':
            set(this, 'isOnPageLeadIntegrationDialog', true);
            break;
          case 'leadIntegrations.tiktok':
            set(this, 'isTikTokLeadIntegrationDialog', true);
            break;
          case 'thirdPartyTokens.third-party-token':
            set(this, 'isSubscriberIntegrationDialog', true);
            break;
        }
      }
      set(this, 'key', null);

      this._fetchPmsIntegrations.perform();
    } catch (error) {
      set(this, 'isError', true);
    }
  }).drop(),
  _fetchPmsIntegrations: task(function* () {
    set(this, 'isError', false);
    const url = `${ENV.APP.apiBaseHost}/${this.organizationSlug}`;

    try {
      let tasks = [];

      const request = this.authenticatedFetch.fetch(`${url}/settings/integrations/pms`, {
        headers: ACCEPT_HEADER
      });

      tasks.push(request);
      tasks.push(timeout(400));

      const [response] = yield all(tasks);

      if (!response || !response.ok) {
        throw new Error('[SETTINGS] pms integrations', response);
      }

      const json = yield response.json();
      if (!json || !json.pmsIntegration) {
        throw new Error('[SETTINGS] pms integrations');
      }

      let pmsIntegrations = json.pmsIntegration;
      if (isArray(pmsIntegrations)) {
        pmsIntegrations = {
          addressBookId: null
        };
      }

      set(this, '_pmsIntegrations', pmsIntegrations);
    } catch (error) {
      set(this, 'isError', true);
    }
  }),

  /**
   * fetches the subscriber integration settings
   *
   * @function fetchSubscriberIntegrationSettings
   * @type {Task}
   */
  fetchSubscriberIntegrationSettings: task(function* () {
    const url = `${ENV.APP.apiBaseHost}/${this.organizationSlug}`;

    try {
      let tasks = [];

      const request = this.authenticatedFetch.fetch(
        `${url}/settings/integrations/third-party-tokens`,
        {
          headers: ACCEPT_HEADER
        }
      );

      tasks.push(request);
      tasks.push(timeout(400));

      const [response] = yield all(tasks);

      if (!response || !response.ok) {
        throw new Error('[SETTINGS] integrations', response);
      }

      const { thirdPartyToken } = yield response.json();

      set(this, 'subscriberIntegrationSettings', thirdPartyToken);
    } catch (error) {
      set(this, 'isError', true);
    }
  }).drop(),

  /**
   * deactivates pms integration settings
   *
   * @function _deactivateAsa
   * @type {Task}
   * @private
   */
  _deactivatePms: task(function* () {
    const url = `${ENV.APP.apiBaseHost}/${this.organizationSlug}`;
    let tasks = [];

    try {
      const request = this.authenticatedFetch.fetch(`${url}/settings/integrations/pms`, {
        headers: ACCEPT_HEADER,
        method: 'PUT',
        body: JSON.stringify({
          addressBookId: null,
          exportMethod: this._pmsIntegrations?.exportMethod ?? null,
          exportEnabled: this._pmsIntegrations?.exportEnabled ?? null,
          deactivatedSalutation: this._pmsIntegrations?.deactivatedSalutation ?? null,
          shouldIgnoreCustomSalutation: this._pmsIntegrations?.shouldIgnoreCustomSalutation ?? null
        })
      });

      tasks.push(request);
      tasks.push(timeout(400));

      const [response] = yield all(tasks);

      if (!response || !response.ok) {
        throw new Error('[SETTINGS] PMS integrations', response);
      }

      set(this, 'integrations.pmsSync.enabled', false);

      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('global.toast.success.savedChanges')
      });
    } catch (e) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('global.toast.error.savedChanges')
      });
    }
  }),

  /**
   * enable/disable pms export
   *
   * @function _togglePmsExport
   * @type {Task}
   * @private
   */
  _togglePmsExport: task(function* (setting) {
    const url = `${ENV.APP.apiBaseHost}/${this.organizationSlug}`;
    let tasks = [];

    const value = this._pmsIntegrations[setting];
    const body = Object.assign(
      {
        addressBookId: this._pmsIntegrations?.addressBookId ?? null,
        exportMethod: this._pmsIntegrations?.exportMethod ?? null,
        exportEnabled: this._pmsIntegrations?.exportEnabled ?? null,
        deactivatedSalutation: this._pmsIntegrations?.deactivatedSalutation ?? null,
        shouldIgnoreCustomSalutation: this._pmsIntegrations?.shouldIgnoreCustomSalutation ?? null
      },
      { [setting]: !value }
    );

    try {
      const request = this.authenticatedFetch.fetch(`${url}/settings/integrations/pms`, {
        headers: ACCEPT_HEADER,
        method: 'PUT',
        body: JSON.stringify(body)
      });

      tasks.push(request);
      tasks.push(timeout(400));

      const [response] = yield all(tasks);
      if (!response?.ok) throw response;

      const json = yield response.json();
      if (!json || !json.pmsIntegration) {
        throw new Error('[SETTINGS] pms integrations');
      }

      set(this, '_pmsIntegrations', json.pmsIntegration);

      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('global.toast.success.savedChanges')
      });
    } catch (e) {
      if (e?.status === 422) {
        return this.uiDialog.showError(
          this.intl.t('settings.integrations.pmsIntegration.deactivatedSalutation.error.title'),
          this.intl.t(
            'settings.integrations.pmsIntegration.deactivatedSalutation.error.description'
          )
        );
      }
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('global.toast.error.savedChanges')
      });
    }
  }),

  /**
   * disconnect of Google Analytics account
   *
   * @function logoutGoogleAnalytics
   * @type {Task}
   */
  logoutGoogleAnalytics: task(function* () {
    const url = `${ENV.APP.apiBaseHost}/${this.organizationSlug}`;

    try {
      let tasks = [];
      const request = this.authenticatedFetch.fetch(`${url}/auth/google/logout`, {
        method: 'POST'
      });

      tasks.push(request);
      tasks.push(timeout(400));

      const [response] = yield all(tasks);
      if (!response?.ok || !response?.status === 204) {
        throw Error('[GOOGLE ANALYTICS] Logout failed');
      }

      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('global.toast.success.savedChanges')
      });

      set(this, 'integrations.googleAnalyticsProfile', null);
      set(this, 'isGoogleAnalyticsDialog', false);
    } catch (error) {
      set(this, 'isError', true);
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('global.toast.error.savedChanges')
      });
    }
  }).drop(),

  actions: {
    googleAnalyticsAction() {
      if (
        this.integrations &&
        (!this.integrations.googleAnalyticsProfile ||
          this.integrations.googleAnalyticsProfile.length === 0)
      ) {
        let redirectUrl = `${ENV.APP.baseHost}/${this.organizationSlug}/settings/integrations?redirectRoute=instance.settings.integrations`;
        redirectUrl = encodeURIComponent(redirectUrl);

        const apiAuthUrl = `${ENV.APP.apiBaseHost}/${this.organizationSlug}/auth/google/loginurl`;

        location.href = `${apiAuthUrl}?redirectUrl=${redirectUrl}`;
        return;
      }

      set(this, 'isGoogleAnalyticsDialog', true);
    },
    transitionTo() {
      setProperties(this, {
        status: null,
        code: null,
        profiles: null,
        redirectRoute: null
      });
      this.router.transitionTo(...arguments);
    }
  }
});
