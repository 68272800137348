export const Integrations = (server) => {
  server.get(`/settings/general`, () => {
    return {
      general: {
        deactivateInactive: {
          enabled: true
        }
      }
    };
  });
};
