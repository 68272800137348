import Controller from '@ember/controller';

import { inject as service } from '@ember/service';
import { action, computed } from '@ember/object';
import { alias, not } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import { all, timeout, task } from 'ember-concurrency';

import { nextTick } from '@additive-apps/ui/utils/dom-util';
import ENV from 'additive-newsletter/config/environment';
import { ACCEPT_HEADER } from 'additive-newsletter/utils/constants';

const IMPORT_INSTRUCTIONS_PDF = 'shared/guides/newsletter_recipients_import_format_{locale}.pdf';

export default class InstanceAddressBooksAddressBookController extends Controller {
  @service authenticatedFetch;
  @service currentUser;
  @service intl;
  @service router;
  @service uiDialog;
  @service uiFilter;
  @service uiLocale;
  @service uiState;
  @service uiToast;

  /**
   * the address book
   *
   * @argument model
   * @type {Object}
   * @default null
   */
  @tracked
  model = null;

  /**
   * the active tab in the filter navigation drawer
   *
   * @property activeNavigationDrawerTab
   * @type {String}
   * @default null
   */
  @tracked
  activeNavigationDrawerTab = null;

  /**
   * the active tab in the address book drawer
   *
   * @property activeAddressBookDrawerTab
   * @type {Number}
   * @default 0
   *
   */
  @tracked
  activeAddressBookDrawerTab = 0;

  /**
   * the abort controller for the file upload
   *
   * @property fileUploadAbortController
   * @type {Object}
   * @default null
   */
  @tracked
  fileUploadAbortController = null;

  /**
   * the key of the filter
   *
   * @property filterKey
   * @type {String}
   * @default null
   */
  @tracked
  filterKey = null;

  /**
   * the list of the saved filters
   *
   * @property filterList
   * @type {Element}
   * @default null
   */
  @tracked
  filterList = null;

  /**
   * the id of the file input
   *
   * @property importFileInputId
   * @type {String}
   * @default 'import-file-input'
   */
  @tracked
  importFileInputId = 'import-file-input';

  /**
   * whether the create dialog is open
   *
   * @property isCreateDialog
   * @type {Boolean}
   * @default false
   */
  @tracked
  isCreateDialog = false;

  /**
   * whether the edit dialog is open
   *
   * @property isEditDialog
   * @type {Boolean}
   * @default false
   */
  @tracked
  isEditDialog = false;

  /**
   * whether the delete dialog is open
   *
   * @property isDeleteDialog
   * @type {Boolean}
   * @default false
   */
  @tracked
  isDeleteDialog = false;

  /**
   * whether a PMS is defined
   *
   * @property isPMSDefined
   * @type {Boolean}
   * @default false
   */
  @tracked
  isPMSDefined = false;

  /**
   * whether the save filter dialog is open
   *
   * @property isSaveFilterDialog
   * @type {Boolean}
   * @default false
   */
  @tracked
  isSaveFilterDialog = false;

  /**
   * whether a file is being uploaded
   *
   * @property isUploadingFile
   * @type {Boolean}
   * @default false
   */
  @tracked
  isUploadingFile = false;

  /**
   * the filter navigation drawer
   *
   * @argument navigationDrawer
   * @type {Element}
   * @default null
   */
  @tracked
  navigationDrawer = null;

  /**
   * whether the import is from PMS
   *
   * @property isPMSImport
   * @type {Boolean}
   * @default false
   */
  @tracked
  isPMSImport = false;

  /**
   * the tabs in the filter navigation drawer
   *
   * @property navigationDrawerTabs
   * @type {Array}
   * @default null
   */
  @tracked
  navigationDrawerTabs = [
    {
      title: 'filters',
      name: 'filters'
    },
    {
      title: 'savedFilters',
      name: 'savedFilters'
    }
  ];

  @alias('currentUser.currentOrganization.id') organizationSlug;
  @alias('addressBookFilter.hasActiveFilter') showFilterNotification;
  @not('addressBookFilter.isOpen') isFilterClosed;

  @computed('filterKey')
  get addressBookFilter() {
    const addressBookFilter = this.uiFilter.getQPFilter('address-book-subscribers');
    return addressBookFilter && addressBookFilter.filter;
  }

  @computed('filterKey')
  get hasExtendedFilters() {
    const dynamicFilters = this.uiFilter.getDynamicFilters('address-book-subscribers');
    return dynamicFilters.some((filter) => filter.groupKey !== 'default');
  }

  @computed('router.currentRouteName')
  get isGroupTab() {
    return this.router.currentRouteName === 'instance.address-books.address-book.groups.index';
  }

  /**
   * link to the import instruction pdf
   *
   * @computed importInstructionsLink
   * @return {String} pdf link
   */
  @computed()
  get importInstructionsLink() {
    const baseUrl = `${ENV.APP.cdnHost}/${ENV.APP.awsBucket}`;

    return `${baseUrl}/${IMPORT_INSTRUCTIONS_PDF.replace(
      '{locale}',
      this.uiLocale?.locale || 'de'
    )}`;
  }

  get navigationDrawerTitle() {
    return this.hasExtendedFilters
      ? this.intl.t('addressBooks.addressBook.subscribers.filter.title')
      : this.intl.t('addressBooks.addressBook.subscribers.filter.savedFilters');
  }

  get isViewer() {
    return this.currentUser.isViewer;
  }

  /**
   * the route of the subscriber detail
   *
   * @computed detailRoute
   * @type {String}
   */
  get detailRoute() {
    if (
      this.router.currentRouteName ===
      'instance.address-books.address-book.subscribers.subscriber.edit'
    ) {
      return 'instance.address-books.address-book.subscribers.subscriber.edit';
    }

    return 'instance.address-books.address-book.subscribers.subscriber.index';
  }

  get navDrawerStateKey() {
    if (this.isGroupTab) {
      return 'address-book-navdrawer-groups';
    }

    return 'address-book-navdrawer-subscribers';
  }

  /**
   * fetch integration settings
   *
   * @function fetchSettings
   * @type {Task}
   */
  @task(function* () {
    try {
      const url = `${ENV.APP.apiBaseHost}/${this.organizationSlug}`;

      let tasks = [];

      const request = this.authenticatedFetch.fetch(`${url}/settings/integrations`, {
        headers: ACCEPT_HEADER
      });

      tasks.push(request);
      tasks.push(timeout(400));

      const [response] = yield all(tasks);

      if (!response || !response.ok) {
        throw new Error('[SETTINGS] integrations', response);
      }

      const { integrations } = yield response.json();

      this.isPMSDefined = integrations?.asaSyncEnabled ? true : false;
    } catch (e) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('global.toast.error.savedChanges'),
        duration: 2500
      });
    }
  })
  fetchSettings;

  @action
  applyFilter(submitExtendedFilter, appliedFilter, closeNavDrawer = true) {
    if (!appliedFilter) {
      return;
    }

    // reset all current filters
    this.uiFilter.reset('address-book-subscribers', true);

    const filters = [];
    Object.keys(appliedFilter.filters).forEach((key) => {
      const value = appliedFilter.filters[key];
      filters.push({ key, value: value?.length === 1 ? value[0] : value });
    });

    if (closeNavDrawer) {
      submitExtendedFilter(filters, this.navigationDrawer.close);
    } else {
      submitExtendedFilter(filters);
    }
  }

  @action
  async setNavigationDrawer(navigationDrawer) {
    await nextTick();
    this.navigationDrawer = navigationDrawer;
  }

  @action
  setInitialNavigationDrawerTab() {
    const initialTabIndex = this.hasExtendedFilters ? 0 : 1;
    this.activeNavigationDrawerTab = this.navigationDrawerTabs[initialTabIndex];
  }

  @action
  onUploadStart() {
    this.fileUploadAbortController = new AbortController();
    this.isUploadingFile = true;
    this.isCreateDialog = false;
  }

  @action
  onUploadEnd() {
    this.isUploadingFile = false;
    this.isCreateDialog = false;
  }

  @action
  openImportDialog(isPMSImport) {
    this.isPMSImport = isPMSImport;

    // Show import file dialog
    document.getElementById(this.importFileInputId).click();
  }

  @action
  toggleDetail() {
    this.uiState.getState(this.navDrawerStateKey).toggle();
  }

  @action
  async filteredExport() {
    let toast = null;
    let tasks = [];

    try {
      const dataFilter = this.uiFilter.getQPFilter('address-book-subscribers');
      const { _queryParamValues, hasNoMatches, isEmpty } = dataFilter && dataFilter.filter;

      if (hasNoMatches || isEmpty) {
        this.uiDialog.showConfirm(
          this.intl.t(`addressBooks.dialogs.downloadEmpty.title`),
          this.intl.t(`addressBooks.dialogs.downloadEmpty.description`)
        );
        return;
      }

      toast = this.uiToast.showToast({
        type: 'loading',
        title: this.intl.t('global.toast.export.loading'),
        duration: -1
      });

      const url = new URL(
        `${ENV.APP.apiBaseHost}/${this.organizationSlug}/addressbooks/${this.model.id}/subscribers/export`
      );

      for (const [key, value] of Object.entries(_queryParamValues)) {
        url.searchParams.set(key, value);
      }

      tasks.push(
        this.authenticatedFetch.fetch(url.href, {
          headers: ACCEPT_HEADER,
          method: 'GET'
        })
      );
      tasks.push(timeout(500));

      const [response] = await all(tasks);
      if (!response || !response.ok) {
        throw new Error('[EXPORT] Error on fetch');
      }
    } catch (error) {
      this.uiDialog.showError();
      return;
    } finally {
      toast && this.uiToast.destroyToast(toast);
    }

    this.uiDialog.showConfirm(
      this.intl.t('addressBooks.dialogs.download.title'),
      this.intl.t('addressBooks.dialogs.download.description', {
        email: this.currentUser.user.email,
        htmlSafe: true
      })
    );
  }
}
