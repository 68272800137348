import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"anl-content-block-v2 relative w-100 flex flex-column items-center\n    {{if this._isDragged 'is-dragged' ''}}\n    {{if this._isHovered 'is-hovered' ''}}\"\n  draggable={{and this.isInteractive (not @isReadOnly)}}\n  {{did-insert this._onInsertElement}}\n>\n  {{this.formatedContentBlock}}\n\n  {{#if this.isInteractive}}\n    <AnlCampaignConfiguratorV2::ActionWrapper\n      @contentBlock={{this.contentBlock}}\n      @isActive={{@isActive}}\n      @isReadOnly={{@isReadOnly}}\n      @position={{@position}}\n      @onChangeVariation={{@onChangeVariation}}\n      @onHover={{fn (mut this._isHovered)}}\n      @onClick={{fn @onClickContentBlock this.contentBlock.model}}\n      @onClickOutside={{@onClickOutside}}\n      @onDelete={{@onDelete}}\n      @onDuplicate={{@onDuplicate}}\n    />\n  {{/if}}\n</div>", {"contents":"<div\n  class=\"anl-content-block-v2 relative w-100 flex flex-column items-center\n    {{if this._isDragged 'is-dragged' ''}}\n    {{if this._isHovered 'is-hovered' ''}}\"\n  draggable={{and this.isInteractive (not @isReadOnly)}}\n  {{did-insert this._onInsertElement}}\n>\n  {{this.formatedContentBlock}}\n\n  {{#if this.isInteractive}}\n    <AnlCampaignConfiguratorV2::ActionWrapper\n      @contentBlock={{this.contentBlock}}\n      @isActive={{@isActive}}\n      @isReadOnly={{@isReadOnly}}\n      @position={{@position}}\n      @onChangeVariation={{@onChangeVariation}}\n      @onHover={{fn (mut this._isHovered)}}\n      @onClick={{fn @onClickContentBlock this.contentBlock.model}}\n      @onClickOutside={{@onClickOutside}}\n      @onDelete={{@onDelete}}\n      @onDuplicate={{@onDuplicate}}\n    />\n  {{/if}}\n</div>","moduleName":"additive-newsletter/components/anl-campaign-configurator-v2/content-block.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-campaign-configurator-v2/content-block.hbs"}});
import Component from '@glimmer/component';
import { htmlSafe } from '@ember/string';
import { action } from '@ember/object';
import { bind, next } from '@ember/runloop';

import { arg } from 'ember-arg-types';
import { boolean, func, number, object } from 'prop-types';
import { tracked } from '@glimmer/tracking';

/**
 * Loads and renders a content block
 *
 * @module anl-campaign-configurator-v2
 * @class AnlCampaignConfiguratorV2ContentBlockComponent
 */
export default class AnlCampaignConfiguratorV2ContentBlockComponent extends Component {
  /**
   * the content of this block
   *
   * @argument contentBlock
   * @type {Object}
   * @default null
   */
  @arg(object)
  contentBlock = null;

  /**
   * the position of this block
   *
   * @argument position
   * @type {Number}
   * @default null
   */
  @arg(number)
  position = null;

  @arg(boolean)
  isInteractive = true;

  /**
   * the main HTML element of this component
   *
   * @property _element
   * @type {HTMLElement}
   * @default null
   * @private
   */
  _element = null;

  /**
   * whether the block is being dragged
   *
   * @property _isDragged
   * @type {Boolean}
   * @default false
   * @private
   */
  @tracked
  _isDragged = false;

  /**
   * whether the block is hovered
   *
   * @property _isHovered
   * @type {Boolean}
   * @default false
   * @private
   */
  @tracked
  _isHovered = false;

  _onDragStart() {
    this.onDragStart(this._element);

    next(this, () => {
      if (!this.isDestroying) {
        this._isDragged = true;
      }
    });
  }

  _onDragEnd() {
    this.onDragEnd();

    next(this, () => {
      if (!this.isDestroying) {
        this._isDragged = false;
      }
    });
  }

  /**
   * the callback triggered on delete
   *
   * @argument onDelete
   * @type {Function}
   */
  @arg(func)
  onDelete = () => {};

  /**
   * the callback triggered on drag start
   *
   * @argument onDragStart
   * @type {Function}
   */
  @arg(func)
  onDragStart = () => {};

  /**
   * the callback triggered on drag end
   *
   * @argument onDragEnd
   * @type {Function}
   */
  @arg(func)
  onDragEnd = () => {};

  /**
   * the callback triggered on duplicate
   *
   * @argument onDuplicate
   * @type {Function}
   */
  @arg(func)
  onDuplicate = () => {};

  @action
  _onInsertElement(element) {
    if (!this.isInteractive) {
      return;
    }

    // setup drag-events for sorting
    this._onDragStart = bind(this, this._onDragStart);
    this._onDragEnd = bind(this, this._onDragEnd);

    if (element) {
      // drag events
      this._element = element;
      this._element.addEventListener('dragstart', this._onDragStart, false);
      this._element.addEventListener('dragend', this._onDragEnd, false);
    }
  }

  /**
   * whether settings are read-only
   *
   * @computed this.contentBlock.html
   * @type {SafeString}
   */
  get formatedContentBlock() {
    const parser = new DOMParser();
    const previewLayout = parser.parseFromString(this.contentBlock.html, 'text/html');
    const aTags = previewLayout.getElementsByTagName('a');
    [...aTags].forEach((aTag) => aTag.setAttribute('target', '_blank'));
    return htmlSafe(previewLayout.documentElement.outerHTML);
  }
}
