import { decamelize } from '@ember/string';
import merge from 'lodash.merge';
import { camelize } from '@ember/string';
import { FONT_FALLBACKS } from './constants/widget-default-values';

/**
 * transforms properties into snake_case since the
 * render service expects this format
 *
 * @function decamelizeKeysDeep
 */
export const decamelizeKeysDeep = (obj) => {
  if (!obj) {
    return obj;
  }
  if (obj instanceof Date) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map((element) => {
      return decamelizeKeysDeep(element);
    });
  }
  if (typeof obj === 'object') {
    Object.keys(obj).forEach((key) => {
      const newKey = decamelize(key);
      obj[newKey] = decamelizeKeysDeep(obj[key]);
      if (key !== newKey) delete obj[key];
    });
  }
  return obj;
};

/**
 * Replaces synced colors with the correct color values
 *
 * @function getColors
 */
export const getColors = (styleColors) => {
  const colors = {};
  Object.keys(styleColors).forEach((key) => {
    const color = styleColors[key];
    if (color.sync) {
      colors[key] = {
        color: color.syncedColor,
        contrastColor: color.syncedContrastColor
      };
    } else {
      colors[key] = { color: color.color, contrastColor: color.contrastColor };
    }
  });
  return colors;
};

/**
 * Replaces synced typographies with the correct font values
 *
 * @function getTypography
 */
export const getTypography = (typography, colors, cd) => {
  const typographies = {};
  Object.keys(typography).forEach((key) => {
    const typo = typography[key];
    if (typo.sync) {
      typographies[key] = { ...cd.corporateDesignTypography[typo.syncedTypography] };
      if (_isCustomFont(typographies[key]?.fontFamily)) {
        const [fontKey, weight] = typographies[key].fontFamily.split('.');
        typographies[key].fontFamily = cd.fonts[fontKey][weight]?.fontFamily;
      }
    } else {
      typographies[key] = typo;
    }
    if (Object.keys(colors).indexOf(typographies[key].color) >= 0) {
      typographies[key].color = colors[typographies[key].color];
    }
  });
  return typographies;
};

/**
 * Formats a block by replacing color, typography, button and synced values into the format that the
 * render service expects
 *
 * @function formatBlock
 */
export const formatBlock = (block, typography, colors, cd, styleModel) => {
  const styles = {};
  block.style?.forEach((style) => {
    style.properties?.forEach((property) => {
      if (property.type === 'typography') {
        if (property.sync) {
          styles[property.id] = typography?.[property.syncedValue];
        } else {
          const font = { ...property.value };
          if (_isCustomFont(font?.fontFamily)) {
            const [fontKey, weight] = font.fontFamily.split('.');
            if (cd.fonts?.[fontKey]?.[weight]) {
              font.fontFamily = cd.fonts[fontKey][weight]?.fontFamily;
            } else {
              font.fontFamily = font.serif ? FONT_FALLBACKS.serif : FONT_FALLBACKS.sansSerif;
            }
          }
          if (typeof font.color === 'string') {
            font.color = colors[font.color];
          }
          styles[property.id] = font;
        }
        /*
          CD fallback font families contain `"` quotes which mess up inline styles
          TODO: check if this can be fixed globally by the API
        */
        if (styles[property?.id]) {
          styles[property.id].fontFamily = styles[property.id]?.fontFamily.replace(/"/g, "'");
        }
      } else if (property.type === 'color') {
        if (property.sync) {
          if (typeof property.syncedValue === 'string') {
            styles[property.id] = colors[property.syncedValue];
          } else {
            styles[property.id] = property.syncedValue;
          }
        } else if (typeof property.value === 'string') {
          styles[property.id] = colors[property.value];
        } else {
          styles[property.id] = property.value;
        }
      } else if (property.sync) {
        // use synced value from style if it is a global property
        styles[property.id] =
          styleModel?.styles?.globals?.[camelize(property.id)] || property.syncedValue;
      } else {
        styles[property.id] = property.value;
      }
    });
  });

  block.style = styles;
  const styledBlock = decamelizeKeysDeep(merge({}, block));

  styledBlock.content = merge(
    {},
    BLOCK_CONTENT[block.blockIdentifier]?.[block.variationIdentifier]
  );

  return styledBlock;
};

const _isCustomFont = (fontFamily) =>
  fontFamily?.startsWith('primary') || fontFamily?.startsWith('secondary');

export const STYLE_PREVIEW_BLOCKS = [
  'header.large',
  'text.primary',
  'button.primary',
  'social_media.link',
  'menu.lines',
  'image_text.framed',
  'gallery.gapped',
  'teaser.default',
  'signature.default',
  'footer.default'
];

/**
 * Temporary block contents.
 * TODO: These contets should be provided by the API
 */
export const BLOCK_CONTENT = {
  button: {
    primary: { button: { text: 'Button' } },
    secondary: { button: { text: 'Button' } },
    link: { button: { text: 'Button' } }
  },
  code: {
    default: {
      name: 'Lorem',
      description: 'Ipsum',
      code: 'Code'
    }
  },
  footer: {
    default: {
      logo: {
        url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_logo.png',
        width: 160,
        height: 160
      },
      text: `
        <h3>Company name</h3>
        <br/>
        <br/>
        <p>Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.</p>
      `,
      unsubscribe: 'unsubscribe',
      disclaimer: 'disclaimer',
      icons: [
        {
          name: 'Youtube',
          url: 'https://imgeasy.com/images/265a9b6cc58a47a507d7fb992f2b026e.png'
        },
        {
          name: 'Facebook',
          url: 'https://imgeasy.com/images/75eafbeb43c6d5e2a18475a725bbefe4.png'
        },
        {
          name: 'Instagram',
          url: 'https://imgeasy.com/images/9749c3a484ca4d52bbdbe6fa31058f5d.png'
        },
        {
          name: 'LinkedIn',
          url: 'https://imgeasy.com/images/8c024dce2dbed27fb7dc7f98be74897a.png'
        }
      ]
    }
  },
  gallery: {
    gapped: {
      images: [
        {
          url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_image_3.jpg',
          text: 'Lorem ipsum',
          link: 'https://www.google.com',
          width: 600,
          height: 600
        },
        {
          url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_image_4.jpg',
          text: 'Lorem ipsum',
          link: 'https://www.google.com',
          width: 600,
          height: 600
        },
        {
          url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_image_5.jpg',
          text: 'Lorem ipsum',
          link: 'https://www.google.com',
          width: 600,
          height: 600
        },
        {
          url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_image_6.jpg',
          text: 'Lorem ipsum',
          link: 'https://www.google.com',
          width: 600,
          height: 600
        }
      ]
    }
  },
  header: {
    small: {
      logo: {
        url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_logo.png',
        width: 160,
        height: 160
      }
    },
    medium: {
      title: { text: 'Lorem ipsum' },
      logo: {
        url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_logo.png',
        width: 160,
        height: 160
      },
      button: {
        text: 'Button'
      },
      background_image: {
        url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_image_1.jpg',
        width: 600,
        height: 480
      }
    },
    large: {
      title: { text: 'Lorem ipsum' },
      logo: {
        url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_logo.png',
        width: 160,
        height: 160
      },
      button: {
        text: 'Button'
      },
      background_image: {
        url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_image_1.jpg',
        width: 600,
        height: 480
      }
    }
  },
  image_text: {
    full_bleed: {
      text: '<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi.</p>',
      button: {
        text: 'Button'
      },
      image: {
        url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_image_3.jpg',
        width: 256,
        height: 256
      }
    },
    framed: {
      text: '<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantiu, totam rem aperiam et quasi.</p>',
      button: {
        text: 'Button'
      },
      image: {
        url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_image_3.jpg',
        width: 256,
        height: 256
      }
    }
  },
  journal: {
    background: {
      journal_post: {
        title: 'At vero eos et accusamus et iusto odio dignissimos ducimequo dolos',
        author_name: 'Max Mustermann',
        author_role: 'Role',
        author_image: {
          url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_person.jpg',
          width: 160,
          height: 160
        }
      },
      link: {
        text: 'Button'
      },
      image: {
        url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_image_7.jpg',
        width: 600,
        height: 480
      }
    },
    framed: {
      journal_post: {
        title: 'At vero eos et accusamus et iusto odio dignissimos ducimequo dolos',
        author_name: 'Max Mustermann',
        author_role: 'Role',
        author_image: {
          url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_person.jpg',
          width: 160,
          height: 160
        }
      },
      link: {
        text: 'Button'
      },
      image: {
        url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_image_7.jpg',
        width: 600,
        height: 480
      }
    }
  },
  logo_reel: {
    packed: {
      logos: [
        {
          url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_logo_reel.png',
          width: 120,
          height: 40
        },
        {
          url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_logo_reel.png',
          width: 120,
          height: 40
        },
        {
          url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_logo_reel.png',
          width: 120,
          height: 40
        }
      ]
    },
    space_between: {
      logos: [
        {
          url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_logo_reel.png',
          width: 120,
          height: 40
        },
        {
          url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_logo_reel.png',
          width: 120,
          height: 40
        },
        {
          url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_logo_reel.png',
          width: 120,
          height: 40
        }
      ]
    }
  },
  media: {
    default: {
      title: 'Title',
      medium: {
        url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_image_6.jpg',
        width: 600,
        height: 480,
        type: 'image'
      }
    }
  },
  menu: {
    lines: {
      item_1: { text: 'Rooms' },
      item_2: { text: 'Offers' },
      item_3: { text: 'Impressions' },
      item_4: { text: 'Request' }
    },
    links: {
      item_1: { text: 'Rooms' },
      item_2: { text: 'Offers' },
      item_3: { text: 'Impressions' },
      item_4: { text: 'Request' }
    },
    buttons: {
      item_1: { text: 'Rooms' },
      item_2: { text: 'Offers' },
      item_3: { text: 'Impressions' },
      item_4: { text: 'Request' }
    }
  },
  offer: {
    background: {
      offer: {
        name: 'Leckeres Frühstück für 2 Personen',
        description: 'This is a short description of the voucher to incentivize a purchase',
        min_amount: 40,
        amount: '120,00 €',
        curreny: '€'
      },
      link: {
        text: 'Button'
      },
      image: {
        url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_image_4.jpg',
        width: 600,
        height: 480
      }
    },
    framed: {
      offer: {
        name: 'Leckeres Frühstück für 2 Personen',
        description: 'This is a short description of the voucher to incentivize a purchase',
        min_amount: 40,
        amount: '120,00 €',
        curreny: '€'
      },
      link: {
        text: 'Button'
      },
      image: {
        url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_image_4.jpg',
        width: 600,
        height: 480
      }
    }
  },
  signature: {
    default: {
      name: 'Max Mustermann',
      description: 'Cook',
      image: {
        url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_person.jpg',
        width: 480,
        height: 480
      }
    }
  },
  social_media: {
    link: {
      channels: [
        {
          name: 'Youtube',
          url: 'https://imgeasy.com/images/265a9b6cc58a47a507d7fb992f2b026e.png'
        },
        {
          name: 'Facebook',
          url: 'https://imgeasy.com/images/75eafbeb43c6d5e2a18475a725bbefe4.png'
        },
        {
          name: 'Instagram',
          url: 'https://imgeasy.com/images/9749c3a484ca4d52bbdbe6fa31058f5d.png'
        }
      ]
    },
    button: {
      channels: [
        {
          name: 'Youtube',
          url: 'https://imgeasy.com/images/265a9b6cc58a47a507d7fb992f2b026e.png'
        },
        {
          name: 'Facebook',
          url: 'https://imgeasy.com/images/75eafbeb43c6d5e2a18475a725bbefe4.png'
        },
        {
          name: 'Instagram',
          url: 'https://imgeasy.com/images/9749c3a484ca4d52bbdbe6fa31058f5d.png'
        }
      ]
    }
  },
  spacer: {
    gap: {},
    line: {},
    image: {
      image: {
        url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_divider.png',
        width: 400,
        height: 56
      }
    }
  },
  survey: {
    default: {
      question:
        '<p>At vero eos et accusamus et iusto odio dignissimos ducimeniti atque corrupti quos dolore?</p>',
      button: {
        text: 'Button'
      }
    },
    options: {
      survey: {
        options: [
          {
            name: 'Option 1'
          },
          {
            name: 'Option 2'
          },
          {
            name: 'Option 3'
          }
        ]
      },
      question:
        '<p>At vero eos et accusamus et iusto odio dignissimos ducimeniti atque corrupti quos dolore?</p>'
    }
  },
  teaser: {
    default: {
      title: { text: 'Titel' },
      button: { text: 'Button' },
      background_image: {
        url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_image_2.jpg',
        width: 600,
        height: 480
      }
    }
  },
  text: {
    primary: {
      text: `
        <h1>At vero eos</h1>
        <br/>
        <br/>
        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>
        <ul>
          <li>odio dignissimos ducimus</li>
          <li>atque corrupti quos dolores</li>
          <li>quas molestias</li>
        </ul>
      `
    },
    secondary: {
      text: `
        <h1>At vero eos</h1>
        <br/>
        <br/>
        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>
        <ul>
          <li>odio dignissimos ducimus</li>
          <li>atque corrupti quos dolores</li>
          <li>quas molestias</li>
        </ul>
      `
    },
    framed: {
      text: `
        <h1>At vero eos</h1>
        <br/>
        <br/>
        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>
        <ul>
          <li>odio dignissimos ducimus</li>
          <li>atque corrupti quos dolores</li>
          <li>quas molestias</li>
        </ul>
      `
    }
  },
  two_columns: {
    full_bleed: {
      image_1: {
        url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_image_1.jpg',
        width: 320,
        height: 320
      },
      image_2: {
        url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_image_2.jpg',
        width: 320,
        height: 320
      },
      text_1:
        '<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi.</p>',
      text_2:
        '<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.</p>',
      button_1: { text: 'Button' },
      button_2: { text: 'Button' }
    },
    framed: {
      image_1: {
        url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_image_1.jpg',
        width: 320,
        height: 320
      },
      image_2: {
        url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_image_2.jpg',
        width: 320,
        height: 320
      },
      text_1:
        '<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi.</p>',
      text_2:
        '<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.</p>',
      button_1: { text: 'Button' },
      button_2: { text: 'Button' }
    }
  },
  voucher: {
    background: {
      voucher: {
        name: 'Leckeres Frühstück',
        description: ' This is a short description of the voucher to incentivize a purchase',
        is_locked: true,
        unlock_code_label: 'Your Code:',
        unlock_code: 'FH48IJ28',
        min_amount: 40,
        amount: '120,00 €',
        amount_with_discount: '60,00 €',
        discount_percentage: 50,
        currency: '€'
      },
      link: {
        text: 'Gutschein'
      },
      image: {
        url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_image_1.jpg',
        width: 600,
        height: 480
      }
    },
    framed: {
      voucher: {
        name: 'Leckeres Frühstück',
        description: ' This is a short description of the voucher to incentivize a purchase',
        is_locked: true,
        unlock_code_label: 'Your Code:',
        unlock_code: 'FH48IJ28',
        min_amount: 40,
        amount: '120,00 €',
        amount_with_discount: '60,00 €',
        discount_percentage: 50,
        currency: '€'
      },
      link: {
        text: 'Gutschein'
      },
      image: {
        url: 'https://additive-newsletter.s3.eu-central-1.amazonaws.com/assets/images/placeholder_image_1.jpg',
        width: 600,
        height: 480
      }
    }
  }
};
