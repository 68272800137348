import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiModal\n  @size=\"sm\"\n  @title={{t \"components.anlAddressBook.groups.listActions.delete.title\"}}\n  @theme=\"error\"\n  @onClose={{this.onClose}}\n  as |modal|\n>\n  <modal.header />\n  <modal.content as |content|>\n    <content.body>\n      <p>\n        {{t\n          \"components.anlAddressBook.groups.listActions.delete.description\"\n          name=this.group.name\n          htmlSafe=true\n        }}\n      </p>\n    </content.body>\n  </modal.content>\n  <modal.footer>\n    <UiButton\n      @label={{t \"global.actions.delete\"}}\n      @isDanger={{true}}\n      @isSecondary={{true}}\n      @onClick={{perform this._deleteGroup}}\n    />\n  </modal.footer>\n</UiModal>", {"contents":"<UiModal\n  @size=\"sm\"\n  @title={{t \"components.anlAddressBook.groups.listActions.delete.title\"}}\n  @theme=\"error\"\n  @onClose={{this.onClose}}\n  as |modal|\n>\n  <modal.header />\n  <modal.content as |content|>\n    <content.body>\n      <p>\n        {{t\n          \"components.anlAddressBook.groups.listActions.delete.description\"\n          name=this.group.name\n          htmlSafe=true\n        }}\n      </p>\n    </content.body>\n  </modal.content>\n  <modal.footer>\n    <UiButton\n      @label={{t \"global.actions.delete\"}}\n      @isDanger={{true}}\n      @isSecondary={{true}}\n      @onClick={{perform this._deleteGroup}}\n    />\n  </modal.footer>\n</UiModal>","moduleName":"additive-newsletter/components/anl-address-book/groups/delete.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-address-book/groups/delete.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

import { arg } from 'ember-arg-types';
import { func, object } from 'prop-types';
import { task } from 'ember-concurrency';

import { ACCEPT_HEADER } from 'additive-newsletter/utils/constants';
/**
 * Handles create and delete of groups
 *
 * @module anl-address-book/groups
 * @class AnlAddressBookGroupsDelete
 */
export default class AnlAddressBookGroupsDelete extends Component {
  @service authenticatedFetch;
  @service intl;
  @service router;
  @service store;
  @service uiDialog;
  @service uiToast;

  /**
   * the addressbook the group belongs to
   *
   * @argument addressBook
   * @type {Model}
   * @default null
   */
  @arg(object)
  addressBook = null;

  /**
   * the group model
   *
   * @argument group
   * @type {Model}
   * @default null
   */
  @arg(object)
  group = null;

  /**		
￼   * computed adapter options		
￼   *		
￼   * @computed _adapterOptions		
￼   * @type {Object}		
￼   */
  get _adapterOptions() {
    return {
      adapterOptions: {
        addressBook: this.addressBook?.id
      }
    };
  }

  get _title() {
    if (this.isCreate) {
      return this.intl.t('components.anlAddressBook.groups.edit.create');
    }

    return this.intl.t('components.anlAddressBook.groups.edit.edit');
  }

  /**
   * deletes the active group
   *
   * @function _deleteGroup
   * @type {Task}
   */
  @task(function* () {
    try {
      const adapter = this.store.adapterFor('group');
      const url = adapter.urlForDeleteRecord(this.group.id, 'group', this._adapterOptions);

      const response = yield this.authenticatedFetch.fetch(url, {
        headers: ACCEPT_HEADER,
        method: 'DELETE'
      });

      if (!response?.ok) {
        if (response.status === 409) {
          const json = yield response.json();

          if (json?.dependencies) {
            const { intl } = this;
            this.uiDialog.showConflictError(
              intl.t('components.anlAddressBook.groups.listActions.delete.conflictError.title'),
              intl.t(
                'components.anlAddressBook.groups.listActions.delete.conflictError.description'
              ),
              json.dependencies
            );

            return;
          }
        }

        throw new Error('[Group] Delete');
      }

      if (this.router.isActive('instance.address-books.address-book.groups.group')) {
        this.router.transitionTo('instance.address-books.address-book.groups');
      }

      yield this.group.unloadRecord();

      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('global.toast.success.savedChanges')
      });
    } catch (error) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('global.toast.error.savedChanges')
      });
    } finally {
      this._isDeleteDialog = false;
    }
  })
  _deleteGroup;

  @arg(func)
  onClose = () => {};
}
