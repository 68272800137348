import Controller from '@ember/controller';

export default class InstanceAddressBooksAddressBookGroupsController extends Controller {
  /**
   * the address book model
   *
   * @property model
   * @type {Model}
   * @default null
   */
  model = null;
}
