import Controller from '@ember/controller';
import { computed, set, action } from '@ember/object';
import { alias, not } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { all, task, timeout } from 'ember-concurrency';

import { encodeSortString } from '@additive-apps/ui/utils/sort-util';
import { nextTick } from '@additive-apps/ui/utils/dom-util';
import ENV from 'additive-newsletter/config/environment';
import { ACCEPT_HEADER } from 'additive-newsletter/utils/constants';

const IMPORT_INSTRUCTIONS_PDF = 'shared/guides/newsletter_recipients_import_format_{locale}.pdf';

export default class InstanceAddressBooksGroupController extends Controller {
  @service authenticatedFetch;
  @service currentUser;
  @service intl;
  @service router;
  @service uiDialog;
  @service uiFilter;
  @service uiLocale;
  @service uiState;
  @service uiToast;

  /**
   * the fetched group
   *
   * @argument group
   * @type {Model}
   * @default null
   */
  group = null;

  /**
   * the model containing the address book and the group
   *
   * @argument model
   * @type {Model}
   * @default null
   */
  model = null;

  /**
   * the active tab in the filter navigation drawer
   *
   * @property activeNavigationDrawerTab
   * @type {String}
   * @default null
   */
  @tracked
  activeNavigationDrawerTab = null;

  /**
   * the abort controller to stop the upload
   * if the user leaves the route
   *
   * @property fileUploadAbortController
   * @type {AbortController}
   * @default null
   */
  @tracked
  fileUploadAbortController = null;

  /**
   * the key of the filter
   *
   * @property filterKey
   * @type {String}
   * @default null
   */
  @tracked
  filterKey = null;

  /**
   * the id of the file input
   *
   * @property importFileInputId
   * @type {String}
   * @default 'import-file-input'
   */
  @tracked
  importFileInputId = 'anl-group-import';

  /**
   * whether a PMS is defined
   *
   * @property isPMSDefined
   * @type {Boolean}
   * @default false
   */
  @tracked
  isPMSDefined = false;

  /**
   * whether the create dialog is open
   *
   * @property isCreateDialog
   * @type {Boolean}
   * @default false
   */
  @tracked
  isCreateDialog = false;

  /**
   * whether there is currenty a file being uploaded
   *
   * @property isUploadingFile
   * @type {Boolean}
   * @default false
   */
  @tracked
  isUploadingFile = false;

  /**
   * the filter navigation drawer
   *
   * @argument navigationDrawer
   * @type {Element}
   * @default null
   */
  @tracked
  navigationDrawer = null;

  /**
   * the tabs in the filter navigation drawer
   *
   * @property navigationDrawerTabs
   * @type {Array}
   * @default null
   */
  @tracked
  navigationDrawerTabs = null;

  /**
   * the selection object
   *
   * @property selection
   * @type {Object}
   */
  @tracked
  selection = null;

  /**
   * the tabs in the filter navigation drawer
   *
   * @property navigationDrawerTabs
   * @type {Array}
   * @default null
   */
  @tracked
  sortState = null;

  /**
   * whether the import is from PMS
   *
   * @property isPMSImport
   * @type {Boolean}
   * @default false
   */
  @tracked
  isPMSImport = false;

  /**
   * whether the rename dialog is open
   *
   * @property _isRenameDialog
   * @type {Boolean}
   * @default false
   * @private
   */
  @tracked
  _isRenameDialog = false;

  /**
   * whether the delete confirm dialog is open
   *
   * @property _isDeleteDialog
   * @type {Boolean}
   * @default false
   * @private
   */
  _isDeleteDialog = false;

  @alias('currentUser.currentOrganization.id') organizationSlug;
  @alias('groupFilter.hasActiveFilter') showFilterNotification;
  @not('groupFilter.isOpen') isFilterClosed;

  @computed('filterKey')
  get groupFilter() {
    const addressBookFilter = this.uiFilter.getQPFilter('group-subscribers');
    return addressBookFilter && addressBookFilter.filter;
  }

  @computed('filterKey')
  get hasExtendedFilters() {
    const dynamicFilters = this.uiFilter.getDynamicFilters('address-book-subscribers');
    return dynamicFilters.some((filter) => filter.groupKey !== 'default');
  }

  /**
   * link to the import instruction pdf
   *
   * @computed importInstructionsLink
   * @return {String} pdf link
   */
  @computed()
  get importInstructionsLink() {
    const baseUrl = `${ENV.APP.cdnHost}/${ENV.APP.awsBucket}`;

    return `${baseUrl}/${IMPORT_INSTRUCTIONS_PDF.replace(
      '{locale}',
      this.uiLocale?.locale || 'de'
    )}`;
  }

  get navigationDrawerTitle() {
    return this.hasExtendedFilters
      ? this.intl.t('addressBooks.addressBook.subscribers.filter.title')
      : this.intl.t('addressBooks.addressBook.subscribers.filter.savedFilters');
  }

  get _isViewer() {
    return this.currentUser.isViewer;
  }

  /**
   * the route of the subscriber detail
   *
   * @computed detailRoute
   * @type {String}
   */
  @computed('router.currentRouteName')
  get detailRoute() {
    if (
      this.router.currentRouteName ===
      'instance.address-books.address-book.groups.group.subscribers.subscriber.edit'
    ) {
      return 'instance.address-books.address-book.groups.group.subscribers.subscriber.edit';
    }

    return 'instance.address-books.address-book.groups.group.subscribers.subscriber.index';
  }

  /**
   * computed boolean whether the detail route is closed
   *
   * @computed isDetailClosed
   * @type {Boolean}
   */
  @computed('detailRoute', 'router.currentRouteName')
  get isDetailClosed() {
    return this.router.currentRouteName !== this.detailRoute;
  }

  constructor() {
    super(...arguments);

    this.navigationDrawerTabs = [
      {
        title: 'filters',
        name: 'filters'
      },
      {
        title: 'savedFilters',
        name: 'savedFilters'
      }
    ];
  }

  /**
   * fetch integration settings
   *
   * @function fetchSettings
   * @type {Task}
   */
  @task(function* () {
    try {
      const url = `${ENV.APP.apiBaseHost}/${this.organizationSlug}`;

      let tasks = [];

      const request = this.authenticatedFetch.fetch(`${url}/settings/integrations`, {
        headers: ACCEPT_HEADER
      });

      tasks.push(request);
      tasks.push(timeout(400));

      const [response] = yield all(tasks);

      if (!response || !response.ok) {
        throw new Error('[SETTINGS] integrations', response);
      }

      const { integrations } = yield response.json();

      this.isPMSDefined = integrations?.asaSyncEnabled ? true : false;
    } catch (e) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('global.toast.error.savedChanges'),
        duration: 2500
      });
    }
  })
  fetchSettings;

  /**
   * fetches the subscriber
   *
   * @function fetchSubscriber
   * @type {Task}
   */
  @task(function* () {
    try {
      const addressBook = this.model && this.model.addressBook;
      const addressBookId = addressBook && addressBook.id;

      const groupId = this.model && this.model.groupId;

      let group = this.store.peekRecord('group', groupId);

      // fetch group only if record is not in store
      if (!group) {
        group = yield this.store.findRecord('group', groupId, {
          adapterOptions: {
            addressBook: addressBookId
          }
        });
      }

      set(this, 'group', group);
    } catch (e) {
      set(this, 'isError', true);
    }
  })
  fetchGroup;

  @action
  applyFilter(submitExtendedFilter, appliedFilter, closeNavDrawer = true) {
    if (!appliedFilter) {
      return;
    }

    // reset all current filters
    this.uiFilter.reset('group-subscribers', true);

    this.uiFilter.reset('address-book-subscribers', true);

    const filters = [];
    Object.keys(appliedFilter.filters).forEach((key) => {
      const value = appliedFilter.filters[key];
      filters.push({ key, value: value?.length === 1 ? value[0] : value });
    });

    if (closeNavDrawer) {
      submitExtendedFilter(filters, this.navigationDrawer.close);
    } else {
      submitExtendedFilter(filters);
    }
  }

  @action
  openImportDialog(isPMSImport) {
    this.isPMSImport = isPMSImport;

    // Show import file dialog
    document.getElementById(this.importFileInputId).click();
  }

  @action
  onBack() {
    this.router.transitionTo('instance.address-books.address-book.groups');
  }

  @action
  openDetail(id) {
    this.router.transitionTo(
      'instance.address-books.address-book.groups.group.subscribers.subscriber',
      id
    );
  }

  @action
  onUploadStart() {
    this.fileUploadAbortController = new AbortController();
    this.isUploadingFile = true;
    this.isCreateDialog = false;
  }

  @action
  onUploadEnd() {
    this.isUploadingFile = false;
    this.isCreateDialog = false;
  }

  @action
  onSort(_, column, direction) {
    const { key } = column;

    this.uiFilter.filter('group-subscribers', 'sort', encodeSortString(key, direction), false);
    this.sortState = { key, direction };
  }

  @action
  setInitialNavigationDrawerTab() {
    const initialTabIndex = this.hasExtendedFilters ? 0 : 1;
    this.activeNavigationDrawerTab = this.navigationDrawerTabs[initialTabIndex];
  }

  @action
  setSelection(selection) {
    nextTick(() => {
      this.selection = selection;
    });
  }

  @action
  toggleDetail() {
    this.uiState.getState('address-book-navdrawer-group').toggle();
  }

  @action
  async filteredExport() {
    let toast = null;
    let tasks = [];

    try {
      const dataFilter = this.uiFilter.getQPFilter('group-subscribers');
      const { _queryParamValues, hasNoMatches, isEmpty } = dataFilter && dataFilter.filter;
      if (hasNoMatches || isEmpty) {
        this.uiDialog.showConfirm(
          this.intl.t(`addressBooks.dialogs.downloadEmpty.title`),
          this.intl.t(`addressBooks.dialogs.downloadEmpty.description`)
        );
        return;
      }

      toast = this.uiToast.showToast({
        type: 'loading',
        title: this.intl.t('global.toast.export.loading'),
        duration: -1
      });

      const url = new URL(
        `${ENV.APP.apiBaseHost}/${this.organizationSlug}/addressbooks/${this.model.addressBook.id}/groups/${this.model.groupId}/subscribers/export`
      );

      for (const [key, value] of Object.entries(_queryParamValues)) {
        url.searchParams.set(key, value);
      }

      tasks.push(
        this.authenticatedFetch.fetch(url.href, {
          headers: ACCEPT_HEADER,
          method: 'GET'
        })
      );
      tasks.push(timeout(500));

      const [response] = await all(tasks);
      if (!response || !response.ok) {
        throw new Error('[EXPORT] Error on fetch');
      }
    } catch (error) {
      this.uiDialog.showError();
      return;
    } finally {
      toast && this.uiToast.destroyToast(toast);
    }

    this.uiDialog.showConfirm(
      this.intl.t('addressBooks.dialogs.download.title'),
      this.intl.t('addressBooks.dialogs.download.description', {
        email: this.currentUser.user.email,
        htmlSafe: true
      })
    );
  }
}
