import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiTagsInputModal\n  @modalClassNames=\"h-auto\"\n  @title={{t \"components.anlCampaigns.testSendDialog.title\"}}\n  @size=\"md\"\n  @changeset={{this._changeset}}\n  @changesetProperty={{\"emails\"}}\n  @buttonLabel={{t \"components.anlCampaigns.testSendDialog.send\"}}\n  @onSave={{perform this.sendTestEmail}}\n  @onClose={{this.onClose}}\n  as |modal|\n>\n  <modal.body>\n    <UiInlineNotification\n      @classNames=\"mv1\"\n      @icon=\"info-state\"\n      @title={{t \"components.anlCampaigns.testSendDialog.info\"}}\n    />\n  </modal.body>\n</UiTagsInputModal>", {"contents":"<UiTagsInputModal\n  @modalClassNames=\"h-auto\"\n  @title={{t \"components.anlCampaigns.testSendDialog.title\"}}\n  @size=\"md\"\n  @changeset={{this._changeset}}\n  @changesetProperty={{\"emails\"}}\n  @buttonLabel={{t \"components.anlCampaigns.testSendDialog.send\"}}\n  @onSave={{perform this.sendTestEmail}}\n  @onClose={{this.onClose}}\n  as |modal|\n>\n  <modal.body>\n    <UiInlineNotification\n      @classNames=\"mv1\"\n      @icon=\"info-state\"\n      @title={{t \"components.anlCampaigns.testSendDialog.info\"}}\n    />\n  </modal.body>\n</UiTagsInputModal>","moduleName":"additive-newsletter/components/anl-campaigns/test-send-dialog.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-campaigns/test-send-dialog.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { arg } from 'ember-arg-types';
import { func, object } from 'prop-types';

import { testSendValidation } from 'additive-newsletter/validations/campaign';
import { ACCEPT_HEADER, ACCEPT_HEADER_V1 } from 'additive-newsletter/utils/constants';

/**
 * Sends a test email for a campaign
 *
 * @class AnlCampaignsTestSendDialog
 * @module anl-campaigns
 */
export default class AnlCampaignsTestSendDialog extends Component {
  @service authenticatedFetch;
  @service intl;
  @service uiDialog;
  @service uiToast;
  @service store;
  @service currentUser;

  /**
   * the current campaign model
   *
   * @argument campaign
   * @type {Object}
   * @default null
   */
  @arg(object)
  @tracked
  campaign = null;

  /**
   * the changeset for the email
   *
   * @property _changeset
   * @type {Object}
   * @default null
   * @private
   */
  @tracked
  _changeset = null;

  /**
   * whether the form was touched
   *
   * @property _isTouched
   * @type {Boolean}
   * @default false
   * @private
   */
  @tracked
  _isTouched = false;

  constructor() {
    super(...arguments);

    this.createChangeset();
  }

  createChangeset() {
    const savedEmail = localStorage.getItem(`${this.currentUser.user?.id}-emails`);
    const emailArray = savedEmail?.split(',');
    const validation = testSendValidation(this.intl);

    this._changeset = new Changeset(
      { emails: emailArray || [] },
      lookupValidator(validation),
      validation
    );
  }

  /**
   * sends the test email and sets the state to success if successful
   *
   * @function sendTestEmail
   * @type {Task}
   */
  @task(function* () {
    const { _changeset, intl, uiToast } = this;

    try {
      this._isTouched = true;

      yield _changeset.validate();
      if (_changeset.get('isInvalid')) return;

      const emails = _changeset.get('emails');
      const adapter = this.store.adapterFor('campaign');
      const url = adapter.buildURL('campaign', this.campaign.id);

      const response = yield this.authenticatedFetch.fetch(`${url}/test-email`, {
        method: 'POST',
        body: JSON.stringify({ emails }),
        headers: this.campaign.version === 2 ? ACCEPT_HEADER : ACCEPT_HEADER_V1
      });

      if (!response || !response.ok) throw new Error();

      // saves the email in the localStorage with user ID as key
      yield localStorage.setItem(`${this.currentUser.user?.id}-emails`, emails);

      this._changeset.rollback();
      uiToast.showToast({
        type: 'success',
        title: intl.t('global.toast.success.savedChanges'),
        duration: 2000
      });

      if (response.status === 202) {
        const json = yield response.json();

        if (json?.warnings) {
          const firstError = Object.values(json.warnings)[0];

          this.uiDialog.showConfirm(
            this.intl.t('components.anlCampaigns.testSendDialog.sentWithWarnings'),
            firstError,
            () => this.onSend()
          );
          return;
        }
      }

      this.onSend();
    } catch (e) {
      this.onError();
      uiToast.showToast({
        type: 'error',
        title: intl.t('global.toast.error.savedChanges'),
        duration: 2000
      });
    }
  })
  sendTestEmail;

  @arg(func)
  onClose = () => {};

  @arg(func)
  onError = () => {};

  @arg(func)
  onSend = () => {};
}
